import axios from 'axios';
import { API_URLS } from '../constants';

// Fonction pour récupérer les données utilisateur
export const fetchUser = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(API_URLS.USER, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.data && response.data.user) {
            return response.data.user;
        }
        throw new Error('User data not found');
    } catch (error) {
        throw new Error('Error fetching user: ' + error.message);
    }
};

// Fonction pour récupérer le profil en utilisant le token d'authentification
export const fetchProfile = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(API_URLS.PROFILE, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.data && response.data.profile) {
            return response.data.profile;
        }
        throw new Error('Profile data not found');
    } catch (error) {
        throw new Error('Error fetching profile: ' + error.message);
    }
};

// Fonction pour sauvegarder ou mettre à jour le profil
export const saveProfile = async (userId, formData) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    };

    try {
        let response;
        if (userId && userId !== '') {
            response = await axios.put(`${API_URLS.PROFILE}/${userId}`, formData, config);
        } else {
            response = await axios.post(API_URLS.PROFILE, formData, config);
        }
        return response.data.message; // Vous pouvez également vérifier la structure de la réponse ici
    } catch (error) {
        throw new Error('Error saving profile: ' + error.message);
    }
};

// Fonction pour mettre à jour seulement le CV (résumé)
export const updateCV = async (formData) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    };

    try {
        const response = await axios.post(API_URLS.UPDATE_CV, formData, config);
        return response.data.message; // Vous pouvez retourner le message ou la structure appropriée de la réponse
    } catch (error) {
        throw new Error('Error updating CV: ' + error.message);
    }
};