import React, { useState, useEffect } from 'react';
import { fetchVillesActives } from '../services/VilleService';
import { fetchContratsActifs } from '../services/TypeContratService';
import { postJob } from '../services/JobsService';

const PublishJob = () => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [salary, setSalary] = useState('');
    const [cityId, setCityId] = useState('');
    const [typeContratId, setTypeContratId] = useState(''); // Type de contrat
    const [vacancies, setVacancies] = useState(1);
    const [experience, setExperience] = useState(0);
    const [lastDate, setLastDate] = useState(''); // Date limite
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [villes, setVilles] = useState([]);
    const [contrats, setContrats] = useState([]);

    const showAlert = (msg, type) => {
        setMessage(msg);
        setError(type === 'danger');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    };

    useEffect(() => {
        const loadVilles = async () => {
            try {
                const villesActives = await fetchVillesActives();
                setVilles(villesActives);
            } catch (error) {
                showAlert('Erreur lors du chargement des villes.', 'danger');
            }
        };

        const loadContrats = async () => {
            try {
                const contratsActifs = await fetchContratsActifs();
                setContrats(contratsActifs);
            } catch (error) {
                showAlert('Erreur lors du chargement des types de contrat.', 'danger');
            }
        };

        loadVilles();
        loadContrats();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const jobData = {
            title: jobTitle,
            description: jobDescription,
            salary: parseFloat(salary) || 0.00,
            city_id: cityId,
            type_contrat_id: typeContratId, // Type de contrat
            number_of_vacancy: vacancies,
            experience: experience,
            last_date: lastDate, // Date limite
            status: 1 // Statut par défaut
        };

        try {
            const response = await postJob(jobData); // Appel de la fonction postJob
            if (response.success) {
                showAlert("L'offre d'emploi a été publiée avec succès !", 'success');
                // Réinitialiser les champs
                setJobTitle('');
                setJobDescription('');
                setSalary('');
                setCityId('');
                setTypeContratId('');
                setVacancies(1);
                setExperience(0);
                setLastDate(''); // Réinitialiser la date limite
            } else {
                showAlert("Échec de la publication de l'offre. Veuillez réessayer.", 'danger');
            }
        } catch (error) {
            console.error(error);
            showAlert("Une erreur est survenue lors de la publication de l'offre.", 'danger');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="submit-area section-gap">
            <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">Publier une Offre d'Emploi</h2>
                <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
                    <div className="form-group col-span-1">
                        <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">Titre de l'offre</label>
                        <input
                            type="text"
                            id="jobTitle"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700">Description de l'offre</label>
                        <textarea
                            id="jobDescription"
                            value={jobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="salary" className="block text-sm font-medium text-gray-700">Salaire (optionnel)</label>
                        <input
                            type="number"
                            id="salary"
                            value={salary}
                            onChange={(e) => setSalary(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="cityId" className="block text-sm font-medium text-gray-700">Ville</label>
                        <select
                            id="cityId"
                            value={cityId}
                            onChange={(e) => setCityId(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option value="">Sélectionnez une ville</option>
                            {villes.map((ville) => (
                                <option key={ville.id} value={ville.id}>{ville.title}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="typeContratId" className="block text-sm font-medium text-gray-700">Type de Contrat</label>
                        <select
                            id="typeContratId"
                            value={typeContratId}
                            onChange={(e) => setTypeContratId(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option value="">Sélectionnez un type de contrat</option>
                            {contrats.map((contrat) => (
                                <option key={contrat.id} value={contrat.id}>{contrat.titre}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="vacancies" className="block text-sm font-medium text-gray-700">Nombre de Postes</label>
                        <input
                            type="number"
                            id="vacancies"
                            value={vacancies}
                            onChange={(e) => setVacancies(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="experience" className="block text-sm font-medium text-gray-700">Expérience (en années)</label>
                        <input
                            type="number"
                            id="experience"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="lastDate" className="block text-sm font-medium text-gray-700">Date Limite</label>
                        <input
                            type="date"
                            id="lastDate"
                            value={lastDate}
                            onChange={(e) => setLastDate(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full bg-blue-600 text-white font-bold py-2 rounded-md ${loading && 'opacity-50 cursor-not-allowed'}`}
                        >
                            {loading ? 'Publication en cours...' : 'Publier l\'offre'}
                        </button>
                    </div>
                </form>
                {message && (
                    <div className={`mt-4 p-2 text-sm ${error ? 'bg-red-200 text-red-700' : 'bg-green-200 text-green-700'} rounded`}>
                        {message}
                    </div>
                )}
            </div>
        </section>
    );
};

export default PublishJob;
