import React, { useState, useEffect } from 'react';
import ActualiteService from '../../services/ActualiteService';
import { BiTimeFive } from 'react-icons/bi';
import Loading from '../../components/core/Loading';
import { media } from '../../styles';

const Actualites = () => {
    const [actualites, setActualites] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActualites = async () => {
            try {
                const data = await ActualiteService.getActiveActualites();
                setActualites(data.map(actualite => ({ ...actualite, expanded: false })));
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des actualités:', error);
                setLoading(false);
            }
        };

        fetchActualites();
    }, []);

    const timeDifference = (date) => {
        const now = new Date();
        const publishedDate = new Date(date);
        const diffInSeconds = Math.floor((now - publishedDate) / 1000);

        if (diffInSeconds < 60) return `${diffInSeconds} sec`;
        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} min`;
        if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hr`;
        if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 86400)} jour${Math.floor(diffInSeconds / 86400) > 1 ? 's' : ''}`;
        if (diffInSeconds < 31536000) return `${Math.floor(diffInSeconds / 2592000)} mois`;
        return `${Math.floor(diffInSeconds / 31536000)} an${Math.floor(diffInSeconds / 31536000) > 1 ? 's' : ''}`;
    };

    const truncateText = (text, limit) => (text.length > limit ? `${text.substring(0, limit)}...` : text);

    const handleToggle = (id) => {
        setActualites(prevActualites =>
            prevActualites.map(actualite =>
                actualite.id === id ? { ...actualite, expanded: !actualite.expanded } : actualite
            )
        );
    };

    if (loading) return <Loading />;

    return (
        <section className={`blog-posts-area section-gap ${media}`}>
            <div className="container">
                <h2 className="title-section">Les Dernières Nouvelles de l'Emploi</h2>
                <div className="content-wrapper">
                    <div className="main-content">
                        <div className="news-grid">
                            {actualites.length > 0 ? (
                                actualites.map((actualite) => (
                                    <div key={actualite.id} className="single-post">
                                        {actualite.image && (
                                            <img
                                                className="img-fluid"
                                                src={actualite.image}
                                                alt={actualite.titre}
                                            />
                                        )}
                                        <a href="#">
                                            <h4 className="title">{actualite.titre}</h4>
                                        </a>
                                        <div className="content">
                                            <p className={`content-text ${actualite.expanded ? 'expanded' : 'collapsed'}`}>
                                                {actualite.expanded ? actualite.contenu : truncateText(actualite.contenu, 150)}
                                            </p>
                                            {actualite.contenu.length > 150 && (
                                                <span
                                                    className="text-primary cursor-pointer toggle-text"
                                                    onClick={() => handleToggle(actualite.id)}
                                                >
                                                    {actualite.expanded ? " moins" : " plus"}
                                                </span>
                                            )}
                                        </div>
                                        <div className="bottom-meta">
                                            <div className="social-wrap">
                                                <ul>
                                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                </ul>
                                            </div>
                                            <div className="comment-wrap">
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            <BiTimeFive /> {timeDifference(actualite.date_publication)} ago
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted">Aucune actualité disponible pour le moment.</p>
                            )}
                        </div>
                    </div>
                    <aside className="sidebar">
                        <div className="single-widget category-widget">
                            <h4 className="title">Post Categories</h4>
                            <ul>
                                <li><a href="#" className="d-flex justify-content-between align-items-center"><h6>Technology</h6> <span>37</span></a></li>
                                {/* <li><a href="#" className="d-flex justify-content-between align-items-center"><h6>Lifestyle</h6> <span>24</span></a></li>
                                <li><a href="#" className="d-flex justify-content-between align-items-center"><h6>Fashion</h6> <span>59</span></a></li>
                                <li><a href="#" className="d-flex justify-content-between align-items-center"><h6>Art</h6> <span>29</span></a></li>
                                <li><a href="#" className="d-flex justify-content-between align-items-center"><h6>Food</h6> <span>15</span></a></li> */}
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>
        </section>
    );
};

export default Actualites;
