import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../constants';

const Error500 = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/');
    };

    return (
        <section className="submit-area section-gap">
            <div className="text-center mt-10">
                <h1 className="text-4xl font-bold">Erreur 500</h1>
                <p className="text-lg">Oups ! Une erreur s'est produite de notre côté. Veuillez réessayer plus tard.</p>
                <img
                    src={images.error404}
                    alt="Erreur 500"
                    className="mt-5 mx-auto"
                    style={{ maxWidth: '300px' }}
                />
                {/* <button
                    onClick={handleRedirect}
                    className="mt-5 bg-blue-500 text-white py-2 px-4 rounded"
                >
                    🏠 Retour à l'accueil
                </button> */}
                <div className="mt-10" />
            </div>
        </section>
    );
};

export default Error500;
