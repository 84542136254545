import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/AuthService';

const Login = ({ toggleModal, switchToRegister, setIsLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const data = await login(email, password);
            const token = data.token;
            localStorage.setItem('token', token);

            if (setIsLoggedIn) {
                setIsLoggedIn(true);
            } else {
                console.error('setIsLoggedIn is not defined');
            }

            // navigate('/dashboard');
            toggleModal();
        } catch (error) {
            setErrorMessage('Email ou mot de passe invalide.');
            console.error('Erreur de connexion :', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-4">
            {/* <h2 className="text-2xl font-semibold mb-4 text-center">Connexion</h2> */}
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700 mb-2">Adresse email <span className="text-red-500">*</span></label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700 mb-2">Mot de passe <span className="text-red-500">*</span></label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                            className="mr-2"
                        />
                        Se souvenir de moi
                    </label>
                </div>
                <button
                    type="submit"
                    className={`w-full ${loading ? 'bg-blue-400' : 'bg-blue-500'} text-white py-2 rounded hover:bg-blue-600 transition duration-300`}
                    disabled={loading}
                >
                    {loading ? 'Chargement...' : 'Se Connecter'}
                </button>
            </form>
        </div>
    );
};

export default Login;
