import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../../services/AuthService';

const Register = ({ toggleModal, setIsLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation du mot de passe
        if (password !== passwordConfirmation) {
            setErrorMessage('Les mots de passe ne correspondent pas.');
            setLoading(false);
            return;
        }

        try {
            const data = await register(username, email, password, passwordConfirmation);
            const token = data.token;
            localStorage.setItem('token', token);

            if (setIsLoggedIn) {
                setIsLoggedIn(true);
            } else {
                console.error('setIsLoggedIn is not defined');
            }

            navigate('/dashboard');
            toggleModal();
        } catch (error) {
            setErrorMessage('Échec de l\'inscription. Veuillez vérifier vos informations et réessayer.');
            console.error('Erreur lors de l\'inscription :', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-4">
            {/* <h2 className="text-2xl font-semibold mb-4 text-center">Créer un compte</h2> */}
            {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="username" className="block text-gray-700 mb-1">Nom d'utilisateur <span className="text-red-500">*</span></label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-gray-700 mb-1">Email <span className="text-red-500">*</span></label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password" className="block text-gray-700 mb-1">Mot de passe <span className="text-red-500">*</span></label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="passwordConfirmation" className="block text-gray-700 mb-1">Confirmer le mot de passe <span className="text-red-500">*</span></label>
                    <input
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className={`w-full ${loading ? 'bg-blue-400' : 'bg-blue-500'} text-white py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300`}
                    disabled={loading}
                >
                    {loading ? 'Chargement...' : 'Créer un compte'}
                </button>
            </form>
        </div>
    );
};

export default Register;
