import React, { useState, useEffect } from 'react';
import { fetchProfile } from '../services/ProfileFormService';
import ProfileForm from '../components/profile/ProfileForm';
import Loading from '../components/core/Loading';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      try {
        const profileData = await fetchProfile(token);
        setProfile(profileData || {});
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleProfileSaved = (message) => {
    // Handle profile saved message, e.g., display a success notification
    alert(message);
  };

  if (loading) return <Loading />;

  if (error) {
    return <p className="text-red-500 text-sm">{error}</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <ProfileForm
        userId={user?.id}
        profileData={profile || {}} // Pass an empty object if profile is null
        onProfileSaved={handleProfileSaved}
      />
    </div>
  );
};

export default Profile;
