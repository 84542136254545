import axios from 'axios';
import { URL_API } from '../constants';

const POSTS_URL = `${URL_API}/applyJob`;

const JobApplyService = {
    // Méthode pour postuler à un emploi
    applyForJob: async (formData) => {
        try {
            const response = await axios.post(POSTS_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (error) {
            throw new Error('Erreur lors de l’envoi de la candidature : ' + error.message);
        }
    },

    // Nouvelle méthode pour récupérer toutes les candidatures
    // getAppliedJobs: async (jobId) => {
    //     try {
    //         const response = await axios.get(`${URL_API}/jobs/${jobId}/has-applied`, {
    //             headers: {
    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`
    //             }
    //         });
    //         console.log(response.data);
    //         return response.data;
    //     } catch (error) {
    //         throw new Error('Erreur lors de la récupération des candidatures : ' + error.message);
    //     }
    // }
};

export default JobApplyService;
