
import axios from 'axios';
import { URL_API } from '../constants';

const API_BASE_URL = `${URL_API}/actualites`;

const getActiveActualites = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}`);
        if (response.data.success) {
            return response.data.data;
        } else {
            throw new Error('Erreur lors de la récupération des actualités');
        }
    } catch (error) {
        console.error('Erreur dans ActualiteService:', error);
        throw error;
    }
};

export default {
    getActiveActualites
};
