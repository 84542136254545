import axios from 'axios';
import { URL_API } from '../constants';

const COMPANIE_URL = `${URL_API}/companies`;

// Fonction pour récupérer toutes les entreprises actives
export const fetchCompaniesActifs = async () => {
    try {
        const response = await axios.get(COMPANIE_URL);
        if (response.data && response.data.data) {
            return response.data.data; // Retourne les données des entreprises
        }
        throw new Error('No companies found');
    } catch (error) {
        throw new Error('Error fetching companies: ' + error.message);
    }
};

// Fonction pour récupérer les détails d'une entreprise par son ID
export const fetchCompanyById = async (id) => {
    try {
        const response = await axios.get(`${COMPANIE_URL}/${id}`);
        if (response.data && response.data.data) {
            return response.data.data;
        }
        throw new Error('Company not found');
    } catch (error) {
        throw new Error('Error fetching company details: ' + error.message);
    }
};

// Fonction pour créer une nouvelle entreprise
export const createCompany = async (companyData) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    };
    try {
        const response = await axios.post(COMPANIE_URL, companyData, config);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Erreur lors de la création de l\'entreprise');
    }
};