import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaMapMarkerAlt, FaPhone, FaGlobe } from 'react-icons/fa';
import { fetchCompanyById } from '../../services/CompanieService';
import { URL, images } from '../../constants';
import Loading from '../core/Loading';
import NotFound from '../../pages/error/NotFound';
import '../../styles/CompanyDetails.css';

const Badge = ({ icon, text }) => (
    <span className="badge">
        {icon} {text}
    </span>
);

const JobItem = ({ job }) => (
    <li className="job-item">
        <h4>{job.title}</h4>
        <p>{job.location}</p>
        <p>{job.type}</p>
        <p>{job.is_remote ? 'Télétravail possible' : 'Sur site'}</p>
    </li>
);

const CompanyDetails = () => {
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCompany = async () => {
            setLoading(true);
            try {
                const data = await fetchCompanyById(id);
                setCompany(data);
            } catch {
                setError("Erreur lors de la récupération des détails de l'entreprise");
            } finally {
                setLoading(false);
            }
        };
        fetchCompany();
    }, [id]);

    if (loading) return <Loading message="Chargement des détails de l'entreprise..." />;
    if (error) return <div>{error}</div>;
    if (!company) return <NotFound />;

    const { cname, address, phone, website, banner, logo, jobs, jobs_count } = company;

    return (
        <div className="company-details-container">
            {/* Header Section */}
            <header className="company-header">
                <div className="company-banner">
                    <img
                        src={banner ? `${URL}/${banner}` : images.imageNotFound}
                        alt={banner ? `${cname} Banner` : 'Image non disponible'}
                    />
                    <div className="company-logo">
                        <img
                            src={logo ? `${URL}/${logo}` : images.imageNotFound}
                            alt={logo ? `${cname} Logo` : 'Image non disponible'}
                        />
                        <p className="company-name">{cname}</p>
                    </div>
                </div>
                <div className="company-info">
                    <div className="company-badges">
                        <Badge icon={<FaMapMarkerAlt />} text={address || 'Adresse non spécifiée'} />
                        <Badge icon={<FaPhone />} text={`Téléphone : ${phone || 'Non spécifié'}`} />
                        <Badge
                            icon={<FaGlobe />}
                            text={website ? (
                                <a href={website} target="_blank" rel="noopener noreferrer">
                                    {website}
                                </a>
                            ) : 'Non spécifié'}
                        />
                    </div>
                    <button className="follow-button">Suivre</button>
                </div>
            </header>

            {/* Job Listings Section */}
            <section className="company-jobs">
                <h3>Offres d'emploi ({jobs_count})</h3>
                <div className="job-filters">
                    <input type="text" placeholder="Rechercher par job, mot-clé ou entreprise" />
                    <button>Type de job</button>
                    <button>Télétravail</button>
                    <button>Professions</button>
                </div>
                <ul className="job-list">
                    {jobs && jobs.length > 0 ? (
                        jobs.map(job => <JobItem key={job.id} job={job} />)
                    ) : (
                        <li className="job-item">Aucune offre d'emploi disponible.</li>
                    )}
                </ul>
            </section>
        </div>
    );
};

export default CompanyDetails;
