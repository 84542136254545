import React, { useState, useEffect } from 'react';
import { fetchProfile, saveProfile } from '../../services/ProfileFormService';

const ProfileForm = ({ userId, profileData }) => {
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [experience, setExperience] = useState('');
    const [phone, setPhone] = useState('');
    const [bio, setBio] = useState('');
    const [coverLetter, setCoverLetter] = useState(null);
    const [resume, setResume] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (profileData) {
            setAddress(profileData.address || '');
            setGender(profileData.gender || '');
            setDob(profileData.dob || '');
            setExperience(profileData.experience || '');
            setPhone(profileData.phone || '');
            setBio(profileData.bio || '');
        }
    }, [profileData]);

    const showAlert = (message, type) => {
        const alertDiv = document.createElement('div');
        alertDiv.classList.add('alert', `alert-${type}`);
        alertDiv.innerText = message;
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '90px';
        alertDiv.style.right = '30px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
        alertDiv.style.backgroundColor = type === 'success' ? '#d4edda' : '#f8d7da';
        alertDiv.style.color = type === 'success' ? '#155724' : '#721c24';
        alertDiv.style.borderColor = type === 'success' ? '#c3e6cb' : '#f5c6cb';

        document.body.appendChild(alertDiv);

        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('address', address);
        formData.append('gender', gender);
        formData.append('dob', dob);
        formData.append('experience', experience);
        formData.append('phone', phone);
        formData.append('bio', bio);
        if (coverLetter) formData.append('cover_letter', coverLetter);
        if (resume) formData.append('resume', resume);

        try {
            const message = await saveProfile(userId, formData);
            setLoading(false);
            showAlert(message || 'Profil enregistré avec succès !', 'success');
        } catch (error) {
            setLoading(false);
            setError(error.response ? error.response.data.message : error.message);
            showAlert(error.message || 'Une erreur est survenue.', 'danger');
        }
    };

    return (
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
            <form onSubmit={handleSubmit} className="p-4 md:p-6">
                <h2 className="text-xl md:text-2xl font-bold text-gray-800 dark:text-white">Formulaire de Profil</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div className="col-span-1">
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="experience" className="block text-sm font-medium text-gray-700">Expérience</label>
                        <input
                            type="text"
                            id="experience"
                            name="experience"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Téléphone</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Sexe</label>
                        <select
                            id="gender"
                            name="gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option value="">Sélectionnez</option>
                            <option value="Homme">Homme</option>
                            <option value="Femme">Femme</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="dob" className="block text-sm font-medium text-gray-700">Date de Naissance</label>
                        <input
                            type="date"
                            id="dob"
                            name="dob"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <label htmlFor="bio" className="block text-sm font-medium text-gray-700">Biographie</label>
                        <textarea
                            id="bio"
                            name="bio"
                            value={bio}
                            onChange={(e) => setBio(e.target.value)}
                            rows={4}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="resume" className="block text-sm font-medium text-gray-700">CV</label>
                        <input
                            type="file"
                            id="resume"
                            name="resume" accept=".pdf,.doc,.docx"
                            onChange={(e) => setResume(e.target.files[0])}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {profileData?.resume && <p className="text-gray-500 text-sm mt-2">CV actuel: {profileData.resume}</p>}
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="coverLetter" className="block text-sm font-medium text-gray-700">Lettre de Consultation</label>
                        <input
                            type="file"
                            id="coverLetter"
                            name="coverLetter" accept=".pdf,.doc,.docx"
                            onChange={(e) => setCoverLetter(e.target.files[0])}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {profileData?.cover_letter && <p className="text-gray-500 text-sm mt-2">Lettre actuelle: {profileData.cover_letter}</p>}
                    </div>
                </div>

                <div className="mt-6">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        {loading ? 'Enregistrement...' : 'Enregistrer'}
                    </button>
                </div>
            </form>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
    );
};

export default ProfileForm;
