import React, { useState, useEffect } from 'react';
import { createCompany } from '../services/CompanieService';

const NewCompany = ({ existingCompanyData }) => {
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [logo, setLogo] = useState(null);
    const [banner, setBanner] = useState(null);
    const [slogan, setSlogan] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (existingCompanyData) {
            setCompanyName(existingCompanyData.cname || '');
            setAddress(existingCompanyData.address || '');
            setPhone(existingCompanyData.phone || '');
            setWebsite(existingCompanyData.website || '');
            setSlogan(existingCompanyData.slogan || '');
            setDescription(existingCompanyData.description || '');
        }
    }, [existingCompanyData]);

    const showAlert = (message, type) => {
        const alertDiv = document.createElement('div');
        alertDiv.classList.add('alert', `alert-${type}`);
        alertDiv.innerText = message;
        alertDiv.style.position = 'fixed';
        alertDiv.style.top = '90px';
        alertDiv.style.right = '30px';
        alertDiv.style.zIndex = '9999';
        alertDiv.style.padding = '10px 20px';
        alertDiv.style.borderRadius = '5px';
        alertDiv.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
        alertDiv.style.backgroundColor = type === 'success' ? '#d4edda' : '#f8d7da';
        alertDiv.style.color = type === 'success' ? '#155724' : '#721c24';
        alertDiv.style.borderColor = type === 'success' ? '#c3e6cb' : '#f5c6cb';

        document.body.appendChild(alertDiv);

        setTimeout(() => {
            alertDiv.remove();
        }, 3000);
    };

    const handleFileChange = (e, setFile) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('cname', companyName);
        formData.append('address', address);
        formData.append('phone', phone);
        formData.append('website', website);
        formData.append('logo', logo);
        formData.append('banner', banner);
        formData.append('slogan', slogan);
        formData.append('description', description);

        try {
            const response = await createCompany(formData);
            setLoading(false);
            if (response.success) {
                showAlert('Votre société a été créée avec succès !', 'success');
                // Reset fields
                setCompanyName('');
                setAddress('');
                setPhone('');
                setWebsite('');
                setLogo(null);
                setBanner(null);
                setSlogan('');
                setDescription('');
            } else {
                showAlert('Échec de la création de la société. Veuillez réessayer.', 'danger');
            }
        } catch (error) {
            setLoading(false);
            setError(error.message || 'Une erreur est survenue.');
            showAlert(error.message || 'Une erreur est survenue.', 'danger');
        }
    };

    return (
        <section className="submit-area section-gap">
            <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">Créer une Nouvelle Société</h2>
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6" encType="multipart/form-data">
                    <div className="form-group col-span-1">
                        <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Nom de la société</label>
                        <input
                            type="text"
                            id="companyName"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
                        <input
                            type="text"
                            id="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Téléphone</label>
                        <input
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="website" className="block text-sm font-medium text-gray-700">Site Web</label>
                        <input
                            type="url"
                            id="website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="logo" className="block text-sm font-medium text-gray-700">Logo</label>
                        <input
                            type="file"
                            id="logo"
                            onChange={(e) => handleFileChange(e, setLogo)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="banner" className="block text-sm font-medium text-gray-700">Bannière</label>
                        <input
                            type="file"
                            id="banner"
                            onChange={(e) => handleFileChange(e, setBanner)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="slogan" className="block text-sm font-medium text-gray-700">Slogan</label>
                        <input
                            type="text"
                            id="slogan"
                            value={slogan}
                            onChange={(e) => setSlogan(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="form-group col-span-1">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    {message && (
                        <div className={`response-message alert-${error ? 'danger' : 'success'}`}>
                            {message}
                        </div>
                    )}
                    <button
                        type="submit"
                        disabled={loading}
                        className="primary-btn header-btn bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        {loading ? 'Chargement...' : 'Créer Société'}
                    </button>
                </form>
            </div>
        </section>
    );
};

export default NewCompany;
