import React, { useState, useEffect, memo } from 'react';
import { fetchCompaniesActifs } from '../services/CompanieService';
import { URL, images } from '../constants';
import Loading from '../components/core/Loading';
import { useNavigate } from 'react-router-dom';
import '../styles/Companies.css';
import { FaMapMarkerAlt, FaGlobe, FaInfoCircle } from 'react-icons/fa';
import Error500 from './error/Error500';

const CompanyCard = memo(({ entreprise, onExpandToggle, isExpanded, handleJobClick }) => (
  <div className="entreprise-card" onClick={onExpandToggle} aria-expanded={isExpanded} tabIndex="0">
    <img
      src={entreprise.banner ? `${URL}/${entreprise.banner}` : images.imageNotFound}
      alt={entreprise.banner ? `${entreprise.cname} Banner` : 'Image non disponible'}
      className="entreprise-banner"
    />
    <div className="entreprise-header">
      <img
        src={entreprise.logo ? `${URL}/${entreprise.logo}` : images.imageNotFound}
        alt={entreprise.logo ? `${entreprise.logo} Logo` : 'Image non disponible'}
        className="entreprise-logo"
      />
      <h4 className="entreprise-name" onClick={handleJobClick}>{entreprise.cname}</h4>
    </div>
    <div className="company-info-container">
      <p className="company-info">
        <FaMapMarkerAlt className="icon" /> {entreprise.address || 'Non spécifié'}
      </p>
      <p className="company-info">
        <FaGlobe className="icon" /> {entreprise.website || 'Non spécifiée'}
      </p>
      <p className="company-info">
        <strong>{entreprise.jobs_count > 0 ? `${entreprise.jobs_count} Offres` : 'Candidature spontanée !'}</strong>
      </p>
      <button className="follow-button">Suivre</button>
    </div>
    {isExpanded && (
      <div className="expanded-details">
        <p>
          <strong>Détails supplémentaires : </strong>
        </p>
        <p className="company-info">
          <FaInfoCircle className="icon" /> {entreprise.description || 'Non spécifiée'}
        </p>
      </div>
    )}
  </div>
));

const Companies = () => {
  const [entreprises, setEntreprises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEntreprises = async () => {
      setLoading(true);
      try {
        const data = await fetchCompaniesActifs();
        setEntreprises(data);
      } catch (error) {
        setError('Erreur lors de la récupération des entreprises');
      } finally {
        setLoading(false);
      }
    };

    fetchEntreprises();
  }, []);

  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleJobClick = (id) => {
    navigate(`/companies/${id}`);
  };

  if (loading) return <Loading />;
  if (error) return <Error500 />;

  return (
    <section className="companies-section">
      <h1 className="section-title">Nouvelles entreprises à explorer</h1>
      <div className="container">
        <div className="companies-grid">
          {entreprises.length === 0 ? (
            <div className="no-offers">
              <p>Aucune entreprise trouvée.</p>
            </div>
          ) : (
            entreprises.map(entreprise => (
              <CompanyCard
                key={entreprise.id}
                entreprise={entreprise}
                onExpandToggle={() => toggleExpand(entreprise.id)}
                isExpanded={expandedId === entreprise.id}
                handleJobClick={() => handleJobClick(entreprise.id)}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Companies;
