import axios from 'axios';
import { URL_API } from '../constants';

const CONTRAT_URL = `${URL_API}/contrats`;

export const fetchContratsActifs = async () => {
    try {
        const response = await axios.get(`${CONTRAT_URL}`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching contrats:', error);
        throw error;
    }
};
