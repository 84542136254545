import React from 'react';

const Alert = ({ message, type, onClose }) => {
    const alertStyles = {
        success: 'bg-green-100 text-green-700',
        danger: 'bg-red-100 text-red-700',
    };

    return (
        <div className={`fixed top-5 right-5 p-4 rounded shadow ${alertStyles[type]}`} role="alert">
            <span>{message}</span>
            <button onClick={onClose} className="ml-4 text-sm font-bold">
                X
            </button>
        </div>
    );
};

export default Alert;
