import axios from 'axios';
import { URL_API } from '../constants';

const JOBS_URL = `${URL_API}/jobs`;

// Fetch all job listings
// export const getJobs = async () => {
//     try {
//         const response = await axios.get(JOBS_URL);
//         return response.data.data;
//     } catch (error) {
//         throw new Error('Erreur lors de la récupération des offres');
//     }
// };

export const getJobs = async () => {
    const token = localStorage.getItem('token');

    // Configuration de l'API
    let apiUrl = JOBS_URL;
    let config = {};  // Configuration sans en-tête d'autorisation

    // Si un token est présent, ajouter l'en-tête Authorization
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }

    try {
        const response = await axios.get(apiUrl, config);

        // Retourner les données nécessaires
        return {
            jobData: response.data.data.map((offer) => ({
                ...offer.job,  // Utilisation uniquement des informations liées à l'offre
                hasApplied: offer.hasApplied || false, // Ajouter si l'utilisateur a postulé
            })),
        };
    } catch (error) {
        throw new Error('Erreur lors de la récupération des offres');
    }
};


export const getJobhasAppliedById = async (id) => {
    const token = localStorage.getItem('token');

    // Config par défaut sans authentification
    let apiUrl = `${JOBS_URL}/${id}`;
    let config = {};

    // Si le token est présent, utiliser l'API protégée par Sanctum
    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        // Modifier l'URL pour appeler la route protégée
        apiUrl = `${JOBS_URL}/${id}/hasApplied`;
    }

    try {
        const response = await axios.get(apiUrl, config);

        // Retourner les données de l'offre d'emploi et si l'utilisateur a postulé
        return {
            jobData: response.data.data,
            hasApplied: response.data.hasApplied || false, // Mettre à false par défaut si non défini
        };
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error(`Le poste avec l'ID ${id} n'a pas été trouvé.`);
        } else if (error.response && error.response.status === 401) {
            throw new Error('Non autorisé. Veuillez vous connecter.');
        } else {
            throw new Error('Erreur lors de la récupération des données.');
        }
    }
};



export const getJobhasApplied = async (id) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
    try {
        const response = await axios.get(`${JOBS_URL}/${id}/has-applied`, config);
        return { jobData: response.data.data, hasApplied: response.data.hasApplied };
    } catch (error) {
        throw new Error(`Erreur lors de la récupération du poste ${id}`);
    }
};

// Fetch a job by its ID
export const getJobById = async (id) => {
    try {
        const response = await axios.get(`${JOBS_URL}/${id}`);
        return response.data.data;
    } catch (error) {
        throw new Error(`Erreur lors de la récupération du poste ${id}`);
    }
};

// Post a new job
export const postJob = async (jobData) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };
    try {
        const response = await axios.post(JOBS_URL, jobData, config);
        return response.data;
    } catch (error) {
        throw new Error('Erreur lors de la création de l\'offre d\'emploi');
    }
};

export default {
    getJobs,
    getJobhasAppliedById,
    getJobhasApplied,
    getJobById,
    postJob,
};