import './Actualites.css';
// import './Companies.css';
// import './CompanyDetails.css';
// import './Dashboard.css';
// import './Jobs.css';
// import './Loading.css';
// import './Navbar.css';
// import './PublishJob.css';
// import './SavedJobs.css';

export const media = 'Actualites';
// export const companies = 'Companies';
// export const companyDetails = 'CompanyDetails';
// export const dashboard = 'Dashboard';
// export const jobs = 'Jobs';
// export const loading = 'Loading';
// export const navbar = 'Navbar';
// export const publishJob = 'PublishJob';
// export const savedJobs = 'SavedJobs';
